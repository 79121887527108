import { createApp, onBeforeMount, onMounted, computed, provide } from 'vue';
import Trix from 'trix';
import 'trix/dist/trix.css';
import { camelizeKeys, camelize } from 'humps';
import InlineSvg from 'vue-inline-svg';
import ActionCableVue from 'actioncable-vue';
import CountryFlag from 'vue-country-flag-next';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import { Form as VeeForm, Field as VeeField } from 'vee-validate';
import linkifyHtml from 'linkify-html';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from '@tanstack/vue-query';
import * as Sentry from '@sentry/browser';
import PostHog from 'posthog-js';
import * as Integrations from '@sentry/integrations';
import '../css/kalio.scss';
import BaseTask from '@/components/shared/base-task.vue';
import BaseInformationCard from '@/components/shared/base-information-card';
import store from '@/store';
import '@/config/vee-validate';
import i18n from '@/plugins/i18n';
import activateTargetBlankLinks from '@/utils/target_blank_links';
import { identifyUser } from '@/utils/analytics';
import {
  DemoDayAuctionOrders,
  DemoDayInternalInvestorApplicationsReview,
  DemoDayBlurbs,
  DemoDayInternalDashboard,
} from '@/demo_day_pack/views';
import InternalApplicationInterview from '@/views/internal/application-interview.vue';
import InternalApplicationInterviewEvaluation from '@/views/internal/application-interview-evaluation.vue';
import InternalFounderProfileReferenceChecks from '@/views/internal/reference-checks.vue';
import InternalStartupReferral from '@/views/internal/startup-referral.vue';
import ApplyNewBig5 from '@/views/apply/new-big5.vue';
import ApplyResultsBig5 from '@/views/apply/results-big5.vue';
import ApplyEditFounderProfile from '@/views/apply/edit-founder-profile.vue';
import InternalModelCompletions from '@/views/internal/model-completions.vue';
import ApplyStatusStartupApplication from '@/views/apply/status-startup-application.vue';
import InternalStartupApplications from '@/views/internal/startup-applications.vue';
import InternalStartupApplication from '@/views/internal/startup-application.vue';
import GroupMeeting from '@/views/group-meeting.vue';
import OfficeHourOfferings from '@/views/office-hour-offerings.vue';
import HomeFounder from '@/views/home/founder.vue';
import HomeBatchFounder from '@/views/home/batch-founder.vue';
import HomePartner from '@/views/home/partner.vue';
import HomeBatchGroupMentor from '@/views/home/batch-group-mentor.vue';
import ApplyRecordedInterview from '@/views/apply/recorded-interview.vue';
import ApplyHomeStartupApplication from '@/views/apply/home-startup-application.vue';
import KalioBaseInput from '@/components/form/kalio-base-input.vue';
import KalioButton from '@/components/shared/kalio-button.vue';
import KalioShortTextInput from '@/components/form/kalio-short-text-input.vue';
import KalioLongTextInput from '@/components/form/kalio-long-text-input.vue';
import KalioNumberRangeInput from '@/components/form/kalio-number-range-input.vue';
import KalioFileInput from '@/components/form/kalio-file-input.vue';
import KalioImageInput from '@/components/form/kalio-image-input.vue';
import KalioSelectInput from '@/components/form/kalio-select-input.vue';
import KalioRadioInput from '@/components/form/kalio-radio-input.vue';
import KalioCopyTextButton from '@/components/shared/kalio-copy-text-button.vue';
import BaseDateInput from '@/components/form/base-date-input.vue';
import KalioTelInput from '@/components/form/kalio-tel-input.vue';
import KalioTrixEditor from '@/components/form/kalio-trix-editor.vue';
import KalioFormSection from '@/components/form/kalio-form-section.vue';
import KalioTextAnswer from '@/components/form/kalio-text-answer.vue';
import ProgressCheckpointBar from '@/components/shared/progress-checkpoint-bar.vue';
import KalioLayout from '@/components/kalio-layout.vue';
import OnboardingOfficeHourOfferingsCarousel from '@/components/onboarding/office-hour-offerings-carousel.vue';
import KalioOfficeHourOffering from '@/components/office-hours/kalio-office-hour-offering.vue';
import KalioOfficeHourOfferingNew from '@/components/office-hours/kalio-office-hour-offering-new.vue';
import KalioUserProfile from '@/components/shared/kalio-user-profile.vue';
import KalioUsers from '@/components/people/kalio-users.vue';
import KalioHeader from '@/components/shared/kalio-header.vue';
import KalioAlert from '@/components/shared/kalio-alert.vue';
import KalioFlashAlert from '@/components/shared/kalio-flash-alert.vue';
import KalioProgramGoalForm from '@/components/program/kalio-program-goal-form.vue';
import KalioProgramGoalPanel from '@/components/program/kalio-program-goal-panel.vue';
import KalioCalAddOfficeHours from '@/components/community/cal/kalio-add-office-hours.vue';
import KalioWeeklyUpdateForm from '@/components/weekly-updates/form.vue';
import KalioWeeklyUpdatesChart from '@/components/weekly-updates/chart.vue';
import GroupMeetingSidebar from '@/components/group-meetings/sidebar.vue';
import GroupMeetingMentorPreparation from '@/components/group-meetings/mentor-preparation.vue';
import KalioGroupMeetingsCompanyInfo from '@/components/group-meetings/company-info.vue';
import CreateUserForm from '@/components/internal/create-user-form.vue';
import InternalInviteUserForm from '@/components/internal/invite-user-form.vue';
import InternalUserProfile from '@/components/internal/users/user-profile.vue';
import KalioCompanyStatus from '@/components/company-status/kalio-company-status.vue';
import KalioCompanyStatusList from '@/components/company-status/kalio-company-status-list.vue';
import KalioSocialMediaIcons from '@/components/shared/kalio-social-media-icons.vue';
import KalioInternalBatchDashboard from '@/components/internal/program/kalio-internal-batch-dashboard.vue';
import KalioCheckbox from '@/components/form/kalio-checkbox.vue';
import KalioCompanyList from '@/components/community/companies/kalio-company-list.vue';
import ProgressCheckpoint from '@/components/shared/progress-checkpoint.vue';
import KalioBackButton from '@/components/shared/kalio-back-button.vue';
import KalioInviteFounders from '@/components/apply/kalio-invite-founders.vue';
import StartupApplicationForm from '@/components/apply/startup-application-form.vue';
import KalioStartupApplicationUpdateForm from '@/components/apply/kalio-startup-application-update-form.vue';
import KalioFormHelper from '@/components/form/kalio-form-helper.vue';
import KalioDemodevRegistration from '@/components/demodev/kalio-demodev-registration.vue';
import KalioLibraryItemList from '@/components/library-items/kalio-library-item-list.vue';
import KalioJobOfferList from '@/components/jobs/kalio-job-offer-list.vue';
import KalioJobApplicationList from '@/components/jobs/kalio-job-application-list.vue';
import KalioFormWrapper from '@/components/shared/kalio-form-wrapper.vue';
import KalioRadioInputs from '@/components/form/kalio-radio-inputs.vue';
import KalioJobOfferForm from '@/components/jobs/kalio-job-offer-form.vue';
import KalioJobOfferShow from '@/components/jobs/kalio-job-offer-show.vue';
import KalioPerkList from '@/components/perks/kalio-perk-list.vue';
import KalioAccordionRow from '@/components/shared/kalio-accordion-row.vue';
import KalioInvestorList from '@/components/investors/kalio-investor-list.vue';
import InvestedFounderCard from '@/components/investors/invested-founder-card.vue';
import ExternalInvestedFounderCard from '@/components/investors/external-invested-founder-card.vue';
import KalioCarousel from '@/components/shared/kalio-carousel.vue';
import KalioStartupReferralForm from '@/components/apply/referrals/kalio-startup-referral-form.vue';
import KalioBatchGroupMeetingsDashboard from '@/components/batch-groups/meetings-dashboard.vue';
import BaseWeeklyUpdateCard from '@/components/weekly-updates/base-card.vue';
import KalioBanner from '@/components/shared/kalio-banner.vue';
import KalioBadge from '@/components/shared/kalio-badge.vue';
import KalioBaseToggle from '@/components/shared/base-toggle.vue';
import KalioInvestmentFirmTags from '@/components/investors/kalio-investment-firm-tags.vue';
import KalioFundingUpdateForm from '@/components/funding-tracking/funding-update-form.vue';
import RailsFlashAlert from '@/components/shared/rails-flash-alert.vue';
import KalioMeeting from '@/components/shared/kalio-meeting.vue';
import ChannelCategories from '@/components/slack-channels/channel-categories.vue';
import KalioFounderCopilotForm from '@/components/founder-copilot/kalio-founder-copilot-form.vue';
import ReviewInPersonInterviewForm from '@/components/internal/apply/review/in-person-interview-form.vue';
import {
  KalioBuildSprintRegistrationForm,
  KalioBuildSprintWeeklyUpdatesCard,
  KalioBuildSprintUpdateForm,
} from '@/build_sprint_pack/components';
import {
  KalioBuildSprintUpdatesReview,
  KalioBuildSprintProjectsReview,
} from '@/build_sprint_pack/components/internal';
import {
  DemoDayApplicationForm,
  DemoDayRules,
  DemoDayVirtualRound,
  DemoDayInvestmentLegalInformation,
  DemoDayStreaming,
  DemoDayInternalInvestorApplicationReview,
  DemoDayVirtualRoundInformationForm,
} from '@/demo_day_pack/components';
import {
  TrackingCompanyUpdateForm,
  TrackingCompanyUpdate,
  TrackingCompanyUpdatesList,
} from '@/tracking_pack/components';
import {
  deaccentisize,
  capitalize,
  toCurrency,
  formatNumber,
  humanize,
  abbreviateNumber,
  secondsToMMSS,
} from '../filters';
import useScreen from '../hooks/screen';
import { useRolesStore, useUserStore, useBatchStore } from '../stores';
import {
  currentUserKey,
  currentUserCompanyKey,
  currentUserCompanyAuctionKey,
  currentUserMentoredBatchGroupKey,
  flashMessagesKey,
} from '../utils/keys';
import clickOutside from '../utils/click_outside.js';

const linkify = {
  mounted: (element) => {
    element.innerHTML = linkifyHtml(element.innerText);
  },
};

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      InternalApplicationInterview,
      InternalApplicationInterviewEvaluation,
      InternalStartupReferral,
      ApplyNewBig5,
      ApplyResultsBig5,
      ApplyEditFounderProfile,
      InternalModelCompletions,
      ApplyStatusStartupApplication,
      InternalStartupApplications,
      InternalStartupApplication,
      GroupMeeting,
      OfficeHourOfferings,
      HomeFounder,
      HomeBatchFounder,
      HomePartner,
      HomeBatchGroupMentor,
      ApplyRecordedInterview,
      ApplyHomeStartupApplication,
      DemoDayAuctionOrders,
      DemoDayInternalInvestorApplicationsReview,
      DemoDayBlurbs,
      DemoDayInternalDashboard,
    },
    setup() {
      const userStore = useUserStore();
      provide(currentUserKey, computed(() => userStore.currentUser));

      onMounted(() => {
        if (process.env.RAILS_ENV === 'production' && userStore.currentUser) {
          if (process.env.POSTHOG_API_KEY) {
            identifyUser(userStore.currentUser.id, {
              id: userStore.currentUser.id,
              email: userStore.currentUser.email,
              username: userStore.currentUser.fullName,
            });
          }

          if (process.env.SENTRY_DSN) {
            Sentry.setUser({
              id: userStore.currentUser.id,
              email: userStore.currentUser.email,
              username: userStore.currentUser.fullName,
            });
          }
        }
      });

      const LANDING_DOMAIN = window.env.LANDING_DOMAIN;
      provide(
        'landingOrigin',
        (LANDING_DOMAIN && `https://${LANDING_DOMAIN}`) || window.location.origin,
      );

      const rolesStore = useRolesStore();
      const batchStore = useBatchStore();

      onBeforeMount(() => {
        if (window.currentBatch) batchStore.setCurrentBatch(camelizeKeys(window.currentBatch));
        if (window.currentUser) userStore.setCurrentUser(camelizeKeys(window.currentUser));
        if (window.currentUserRoles) rolesStore.setCurrentUserRoles(camelizeKeys(window.currentUserRoles));
        if (window.currentUserCompany) provide(currentUserCompanyKey, camelizeKeys(window.currentUserCompany));
        if (window.currentUserCompanyAuction) {
          provide(currentUserCompanyAuctionKey, camelizeKeys(window.currentUserCompanyAuction));
        }
        if (window.currentUserMentoredBatchGroup) {
          provide(
            currentUserMentoredBatchGroupKey,
            camelizeKeys(window.currentUserMentoredBatchGroup),
          );
        }

        provide(flashMessagesKey, window.flashMessages);
      });

      onMounted(() => {
        if (document.getElementsByClassName('editable-content').length > 0) {
          activateTargetBlankLinks('editable-content');
        }
      });

      const { screen } = useScreen();

      return {
        screen,
      };
    },
  });

  if (process.env.RAILS_ENV === 'production') {
    if (process.env.POSTHOG_API_KEY) {
      /* eslint-disable camelcase */
      PostHog.init(process.env.POSTHOG_API_KEY, {
        api_host: `https://${process.env.POSTHOG_HOST}`,
        session_recording: {
          maskAllInputs: true, // Important - this needs to be true for the below function to be called
          maskInputFn: (text, element) => {
            if (element?.attributes.type?.value === 'password') {
              return '*'.repeat(text.length);
            }

            return text;
          },
        },
      });
      /* eslint-enable camelcase */
    }

    if (process.env.SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [
          new Integrations.Vue({ Vue: app, attachProps: true }),
          new PostHog.SentryIntegration(PostHog, 'Platanus Ventures', process.env.POSTHOG_PROJECT_ID),
        ],
      });
    }
  }

  app.config.globalProperties.$filters = {
    camelizeKeys,
    camelize,
    deaccentisize,
    capitalize,
    toCurrency,
    formatNumber,
    humanize,
    abbreviateNumber,
    secondsToMMSS,
  };

  app.config.globalProperties.window = window;

  app.component('kalio-base-input', KalioBaseInput)
    .component('kalio-button', KalioButton)
    .component('kalio-short-text-input', KalioShortTextInput)
    .component('kalio-long-text-input', KalioLongTextInput)
    .component('kalio-number-range-input', KalioNumberRangeInput)
    .component('kalio-file-input', KalioFileInput)
    .component('kalio-image-input', KalioImageInput)
    .component('kalio-select-input', KalioSelectInput)
    .component('kalio-radio-input', KalioRadioInput)
    .component('kalio-copy-text-button', KalioCopyTextButton)
    .component('base-date-input', BaseDateInput)
    .component('kalio-tel-input', KalioTelInput)
    .component('kalio-trix-editor', KalioTrixEditor)
    .component('kalio-form-section', KalioFormSection)
    .component('kalio-text-answer', KalioTextAnswer)
    .component('progress-checkpoint-bar', ProgressCheckpointBar)
    .component('kalio-layout', KalioLayout)
    .component('onboarding-office-hour-offerings-carousel', OnboardingOfficeHourOfferingsCarousel)
    .component('kalio-office-hour-offering', KalioOfficeHourOffering)
    .component('kalio-office-hour-offering-new', KalioOfficeHourOfferingNew)
    .component('kalio-user-profile', KalioUserProfile)
    .component('kalio-users', KalioUsers)
    .component('kalio-header', KalioHeader)
    .component('demo-day-application-form', DemoDayApplicationForm)
    .component('kalio-alert', KalioAlert)
    .component('kalio-flash-alert', KalioFlashAlert)
    .component('kalio-program-goal-form', KalioProgramGoalForm)
    .component('kalio-program-goal-panel', KalioProgramGoalPanel)
    .component('kalio-cal-add-office-hours', KalioCalAddOfficeHours)
    .component('kalio-weekly-update-form', KalioWeeklyUpdateForm)
    .component('kalio-weekly-updates-chart', KalioWeeklyUpdatesChart)
    .component('group-meeting-sidebar', GroupMeetingSidebar)
    .component('group-meeting-mentor-preparation', GroupMeetingMentorPreparation)
    .component('kalio-group-meetings-company-info', KalioGroupMeetingsCompanyInfo)
    .component('create-user-form', CreateUserForm)
    .component('internal-invite-user-form', InternalInviteUserForm)
    .component('internal-user-profile', InternalUserProfile)
    .component('internal-founder-profile-reference-checks', InternalFounderProfileReferenceChecks)
    .component('kalio-company-status', KalioCompanyStatus)
    .component('kalio-company-status-list', KalioCompanyStatusList)
    .component('kalio-social-media-icons', KalioSocialMediaIcons)
    .component('kalio-internal-batch-dashboard', KalioInternalBatchDashboard)
    .component('kalio-checkbox', KalioCheckbox)
    .component('kalio-company-list', KalioCompanyList)
    .component('progress-checkpoint', ProgressCheckpoint)
    .component('kalio-back-button', KalioBackButton)
    .component('kalio-invite-founders', KalioInviteFounders)
    .component('startup-application-form', StartupApplicationForm)
    .component('kalio-startup-application-update-form', KalioStartupApplicationUpdateForm)
    .component('kalio-form-helper', KalioFormHelper)
    .component('kalio-demodev-registration', KalioDemodevRegistration)
    .component('kalio-library-item-list', KalioLibraryItemList)
    .component('kalio-job-offer-list', KalioJobOfferList)
    .component('kalio-job-application-list', KalioJobApplicationList)
    .component('kalio-form-wrapper', KalioFormWrapper)
    .component('demo-day-rules', DemoDayRules)
    .component('kalio-radio-inputs', KalioRadioInputs)
    .component('kalio-job-offer-form', KalioJobOfferForm)
    .component('kalio-job-offer-show', KalioJobOfferShow)
    .component('demo-day-virtual-round', DemoDayVirtualRound)
    .component('demo-day-investment-legal-information', DemoDayInvestmentLegalInformation)
    .component('demo-day-streaming', DemoDayStreaming)
    .component('country-flag', CountryFlag)
    .component('kalio-perk-list', KalioPerkList)
    .component('kalio-build-sprint-updates-review', KalioBuildSprintUpdatesReview)
    .component('kalio-build-sprint-projects-review', KalioBuildSprintProjectsReview)
    .component('kalio-build-sprint-registration-form', KalioBuildSprintRegistrationForm)
    .component('kalio-build-sprint-weekly-updates-card', KalioBuildSprintWeeklyUpdatesCard)
    .component('kalio-accordion-row', KalioAccordionRow)
    .component('kalio-build-sprint-update-form', KalioBuildSprintUpdateForm)
    .component('kalio-investor-list', KalioInvestorList)
    .component('invested-founder-card', InvestedFounderCard)
    .component('external-invested-founder-card', ExternalInvestedFounderCard)
    .component('kalio-carousel', KalioCarousel)
    .component('tracking-company-update-form', TrackingCompanyUpdateForm)
    .component('tracking-company-update', TrackingCompanyUpdate)
    .component('tracking-company-updates-list', TrackingCompanyUpdatesList)
    .component('kalio-startup-referral-form', KalioStartupReferralForm)
    .component('kalio-batch-group-meetings-dashboard', KalioBatchGroupMeetingsDashboard)
    .component('base-weekly-update-card', BaseWeeklyUpdateCard)
    .component('demo-day-internal-investor-application-review', DemoDayInternalInvestorApplicationReview)
    .component('kalio-banner', KalioBanner)
    .component('demo-day-virtual-round-information-form', DemoDayVirtualRoundInformationForm)
    .component('kalio-badge', KalioBadge)
    .component('kalio-base-toggle', KalioBaseToggle)
    .component('kalio-investment-firm-tags', KalioInvestmentFirmTags)
    .component('kalio-funding-update-form', KalioFundingUpdateForm)
    .component('base-task', BaseTask)
    .component('base-information-card', BaseInformationCard)
    .component('rails-flash-alert', RailsFlashAlert)
    .component('kalio-meeting', KalioMeeting)
    .component('channel-categories', ChannelCategories)
    .component('kalio-founder-copilot-form', KalioFounderCopilotForm)
    .component('review-in-person-interview-form', ReviewInPersonInterviewForm);

  app.component('vee-form', VeeForm);
  app.component('vee-field', VeeField);
  app.component('inline-svg', InlineSvg);

  app.use(ActionCableVue, {
    connectionUrl: '/cable',
    connectImmediately: true,
    debug: true,
    debugLevel: 'error',
  });
  app.use(FloatingVue);
  app.use(Trix);
  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    },
  });

  app.directive('linkified', linkify);
  app.directive('click-outside', clickOutside);

  app.use(i18n);
  app.use(store);

  const pinia = createPinia();
  app.use(pinia);

  app.mount('#vue-app');

  return app;
});
